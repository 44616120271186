<template lang="pug">
    section.content
        div.container-fluid
            div.row
                div.col-sm-12
                    center
                        img.img-fluid(:src="sCoverPagePath" style="width:55%")
            div.row(style="float:right; padding-right:20px")
                button.btn.btn-sm.btn-success(@click="goToExam()" )  {{ $t('btns.btnirexam') }}


</template>

<script>
/* eslint-disable */
export default {
    name: 'CoverPage',
    props: ['idPersonExam', 'idGroupDetail', 'idExam'],
    components: {
    },
    data() {
        return {
            idPersonExam: 0,
            idGroupDetail: 0,
            sCoverPagePath: ''
        }
    },
    methods: {
        async getCoverpagePath()
        {
            await axios.get(apiURL + 'Student/getCoverPage?idExam='+ this.idExam, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.sCoverPagePath = response.data.stringValue
            } )
            .catch( (error)  => {
                console.log("error")
            } )                  
        },
        async goToExam(){
            router.push('/student/takeExam2/' + this.idPersonExam + '/' + this.idGroupDetail)
        }
    },
    mounted() {
        this.getCoverpagePath()
        //this.checkPermisions(this.$router.currentRoute.path)
        
    }
}
</script>
